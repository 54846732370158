import {React, useEffect} from 'react';
import { BrowserRouter, Routes, Route, useLocation, useNavigate } from "react-router-dom";
import './App.css';

import { AnimatePresence, motion } from 'framer-motion';
import Home from '../Home/Home'
import About from '../About/About'
import GraphicDesign from '../Portfolio/GraphicDesign/GraphicDesign'
import AppsWeb from '../Portfolio/AppsWeb/Appsweb'
import AppWeb from '../Portfolio/AppsWeb/Appweb'
import Photography from '../Portfolio/Photography/Photography'
import Video from '../Portfolio/Video/Videos'
import Artwork from '../Portfolio/ArtWork/Artwork'
import Resume from '../Resume/Resume'
import Contact from '../Contact/Contact'
import Navigation from '../Navigation/Navigation'
import Education from "../Education/Education"

// const routeTransition = {
//   hidden: { opacity: 0, scale: 0.8 },
//   visible: { opacity: 1, scale: 1 },
//   exit: { opacity: 0, scale: 0.8 }
// };
const routeTransition = {
  hidden: { x: '-100vw', backgroundColor: 'purple' },
  visible: { x: 0, backgroundColor: 'white', transition: { duration: 0.5 } },
  exit: { x: '100vw', backgroundColor: 'white', transition: { duration: 0.5 } },
};

const Shop = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.location.href = 'https://shop.dcartist.com/';
  }, [navigate]);

  return null;
};

function App() {
  console.warn = console.error = () => {};
  // console.log = console.warn = console.error = () => {};
  document.addEventListener('contextmenu', (e) => {
    e.preventDefault();
  });
  var date = new Date();
  var res = date.getFullYear();
  const location = useLocation();
  return (
  <div>  


<div className="App">
<Navigation></Navigation>
<AnimatePresence mode='wait'>
<Routes location={location} key={location.key}>
{/* <Route exact path="/"  element={<Home/>} /> */}
<Route exact path="/"  element={<motion.div variants={routeTransition} initial="hidden" animate="visible" exit="exit">
              <Home />
            </motion.div>} />
{/* <Route path="/about" element={<About/>}/> */}
<Route path="/about" element={
            <motion.div variants={routeTransition} initial="hidden" animate="visible" exit="exit">
              <About />
            </motion.div>
          }/>
{/* <Route path="/about" element={  <motion.div variants={routeTransition} initial="hidden" animate="visible" exit="exit">
              <About />
            </motion.div>}/> */}
<Route path="/apps" element={
            <motion.div variants={routeTransition} initial="hidden" animate="visible" exit="exit">
              <AppsWeb />
            </motion.div>
          }/>
<Route path="/education" element={
            <motion.div variants={routeTransition} initial="hidden" animate="visible" exit="exit">
              <Education />
            </motion.div>
          }/>
<Route path="/contact" element={
            <motion.div variants={routeTransition} initial="hidden" animate="visible" exit="exit">
              <Contact />
            </motion.div>
          }/>
<Route path="/graphicdesign" element={
            <motion.div variants={routeTransition} initial="hidden" animate="visible" exit="exit">
              <GraphicDesign />
            </motion.div>
          }/>
<Route path="/resume" element={
            <motion.div variants={routeTransition} initial="hidden" animate="visible" exit="exit">
              <Resume />
            </motion.div>
          }/>
<Route path="/video" element={
            <motion.div variants={routeTransition} initial="hidden" animate="visible" exit="exit">
              <Video />
            </motion.div>
          }/>
<Route path="/photography" element={
            <motion.div variants={routeTransition} initial="hidden" animate="visible" exit="exit">
              <Photography />
            </motion.div>
          }/>
<Route path="/artwork" element={
            <motion.div variants={routeTransition} initial="hidden" animate="visible" exit="exit">
              <Artwork />
            </motion.div>
          }/>

<Route path="/shop" element={<Shop />} />

{/* <Route path="/apps" element={<AppsWeb/>} exact/>
<Route path="/education" element={<Education/>}/>
<Route path="/apps1" element={<AppWeb/>} exact/>
<Route path="/contact" element={<Contact/>}/>
<Route path="/graphicdesign" element={<GraphicDesign/>}/>
<Route path="/resume" element={<Resume/>}/>
<Route path="/video" element={<Video/>}/>
<Route path="/photography" element={<Photography/>}/>
<Route path="/artwork" element={<Artwork/>}/> */}
  </Routes>

  </AnimatePresence>
</div>
<div className="copyright">
  <p>©2003 - {res} DCartist - Paula Bannerman. All Rights Reserved. </p>
</div>

</div>
  );
}

export default App;
