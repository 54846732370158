import React, { Component } from 'react'
import { Menu, Segment } from 'semantic-ui-react'

export default class MenuExampleInvertedSecondary extends Component {
  constructor(props){
    super()
    this.state = { activeItem: 'Show All' }
  }

  handleItemClick = (e, { name }) => {
    name === "Database & APIs" ? (this.setState({ activeItem: "Database" })) : this.setState({ activeItem: name })
    name === "Database & APIs" ? this.props.categoryChange("Database") : this.props.categoryChange(name)
    
  }

  render() {
    const { activeItem } = this.state

    return (
      <Segment textAlign='center' >
        <Menu pointing secondary>
          <Menu.Item
            name='CSS'
            active={activeItem === 'CSS'}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name='Javascript'
            active={activeItem === 'Javascript'}
            onClick={this.handleItemClick}
          />
          {/* <Menu.Item
            name='Angular'
            active={activeItem === 'Angular'}
            onClick={this.handleItemClick}
          /> */}
          <Menu.Item
            name='ReactJS'
            active={activeItem === 'ReactJS' && activeItem === 'Angular'}
            onClick={this.handleItemClick}
          />
          {/* <Menu.Item
            name='WordPress'
            active={activeItem === 'WordPress'}
            onClick={this.handleItemClick}
            content="WordPress">Wordpress</Menu.Item> */}
          <Menu.Item
            name='PHP'
            active={activeItem === 'PHP'}
            onClick={this.handleItemClick}
            content="PHP"
          />
          <Menu.Item
            name='Database & APIs'
            active={activeItem === 'Database & APIs'}
            onClick={this.handleItemClick}> Database & APIs</Menu.Item>
          <Menu.Item
            name='Python'
            active={activeItem === 'Python'}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name='Show All'
            active={activeItem === 'Show All'}
            onClick={this.handleItemClick}
          />
        </Menu>
      </Segment>
    )
  }
}